import * as React from 'react'
import { graphql } from 'gatsby'
import { BlockTitle, Container } from 'components/common/styles'
import styled from '@emotion/styled'
import Sidebar from 'components/pages/legal/sidebar'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from 'layouts/default'
import { between } from 'polished'
import Helmet from 'react-helmet'
import { Document } from '@contentful/rich-text-types'

type Props = {
  data: {
    allContentfulLegal: {
      edges: [{
        node: {
          childContentfulLegalBodyRichTextNode: {
            json: Document
          }
          title: string
          slug: string
        }
      }]
    }
  },
  location: string
  slug: string
};

const LocalContainer = styled(Container)`
  margin-top: 56px;
  margin-bottom: 90px;
  
  flex-direction: row;
  justify-content: space-between;
  
  max-width: 1280px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Title = styled(BlockTitle)``

const Content = styled.div`
  flex: 1;
  padding-left: 100px;
  
  @media screen and (max-width: 1300px) {
    padding-left: ${between('50px', '150px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    padding-left: 0;
    border-top: 1px solid #BDBDBD;
    padding-top: 40px;
  }
  
  h2 {
    font-size: 24px;
    line-height: 1.5;
    color: #000;
    font-weight: bold;
    margin-bottom: .5em;
  }
  
  h3 {
    font-size: 20px;
    line-height: 1.5;
    color: #000;
    font-weight: bold;
    margin-bottom: .5em;
  }
  
  b {
    font-weight: bold;
  }
  
  p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0.3em;
    color: #666668;
  }
  
  p + h2 {
    margin-top: 30px;
  }
  
  p + h3 {
    margin-top: 15px;
  }
  
  a {
    color: ${props => props.theme.colors.green100};
    font-weight: 500;
  }
  
  ul {
    margin-left: 2em;
    list-style: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    li {
      &:not(:last-of-type) {
        margin-bottom: 0.3em;
      }
    }
  }
  
  ol {
    margin-left: 2em;
    list-style: decimal;
    li {
      &:not(:last-of-type) {
        margin-bottom: 0.3em;
      }
    }
    margin-bottom: 1em;
  }
`

const LegalPage: React.FC<Props> = ({ location, data }) => {
  React.useEffect(() => {
    window.analytics.page('Legal', data.allContentfulLegal.edges[0].node.title)
  }, [])
  return (
    <Layout location={location}>
      <Helmet title={`${data.allContentfulLegal.edges[0].node.title} | Legal | ComCard`} />
      <LocalContainer>
        <Sidebar activeItem={data.allContentfulLegal.edges[0].node.slug} />
        <Content>
          <Title>{data.allContentfulLegal.edges[0].node.title}</Title>
          {documentToReactComponents(data.allContentfulLegal.edges[0].node.childContentfulLegalBodyRichTextNode.json)}
        </Content>
      </LocalContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
    query LegalPage($slug: String!){
        allContentfulLegal(filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    childContentfulLegalBodyRichTextNode {
                        json
                    }
                    title
                    slug
                }
            }
        }
    }
`

export default LegalPage

import * as React from 'react'
import styled from '@emotion/styled'
import { graphql, Link, StaticQuery } from 'gatsby'

type Props = {
  activeItem: string
  data?: {
    allContentfulLegalSidebar: {
      edges: [
        {
          node: {
            pages: [
              {
                slug: string
                title: string
              }
            ]
          }
        }
      ]
    }
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

type ItemProps = {
  isActive: boolean
}

const Item = styled(Link)<ItemProps>`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: none;
  color: ${props => props.isActive ? props.theme.colors.green100 : '#000'};
`

const Sidebar: React.FC<Props> = ({ activeItem, data }) => {
  return (
    <Container>
      <StaticQuery
        query={graphql`
                    query {
                        allContentfulLegalSidebar {
                            edges {
                                node {
                                    pages {
                                        slug
                                        sidebarTitle
                                    }
                                }
                            }
                        }
                    }
    `}
        render={data => (
          <>
            {
              data.allContentfulLegalSidebar.edges[0].node.pages.map(page => (
                <Item to={`/legal/${page.slug}`} isActive={activeItem === page.slug}>{page.sidebarTitle}</Item>
              ))
            }
          </>
        )}
      />

    </Container>
  )
}

export default Sidebar
